import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import getMetaTags from '../utils/seo'

import Layout from '../components/layout'
import PageTitle from '../components/page/title'
import PageIntro from '../components/page/intro'
import RentList from '../components/rent/list'
import RentItem from '../components/rent/item'

export const RentPageTemplate = ({ title, intro, list }) => (
  <div>
    <PageTitle title={title} />
    <PageIntro html={intro} />
    <RentList>
      {list.map(item => <RentItem key={item.path} post={item} />)}
    </RentList>
  </div>
)

const RentPage = ({ data }) => {
  const { frontmatter: { title, meta }, html } = data.markdownRemark

  const list = data.allMarkdownRemark
    ? data.allMarkdownRemark.edges.map(({ node: { fields: { slug: path }, html, frontmatter } }) =>
      ({ ...frontmatter, intro: html, path })) : []

  return (
    <Layout>
      <Helmet title={'Tehnika rent'} meta={getMetaTags(meta)} />
      <RentPageTemplate title={title} intro={html} list={list} />
    </Layout>
  )
}

export default RentPage

export const rentPageQuery = graphql`
  query RentPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        meta {
          description
          tags
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___created] },
      filter: { frontmatter: { templateKey: { eq: "RentDetailTemplate" } }}
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          html
          frontmatter {
            title
            gallery {
              name {
                childImageSharp {
                  fixed(width: 800, height: 800) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
