import React from 'react'

import './list.scss'

const RentList = ({ children }) => (
  <div className='b-rent-items'>
    <div className='l-main'>
      {children}
    </div>
  </div>
)

export default RentList
