import React from 'react'
import { Link } from 'gatsby'

import './item.scss'

const RentItem = ({ post }) => {
  const { title, gallery, path, intro: __html } = post

  const [first] = gallery

  return (
    <div className='b-rent-item'>
      <div className='b-rent-item-image'>
        {
          (!first || first.name === null) ||
            <figure style={{ backgroundImage: `url('${first.name.childImageSharp.fixed.src}')` }} />
        }
      </div>
      <div className='b-rent-item-main'>
        <div className='b-rent-item-main-content'>
          <div className='b-rent-item-main-content-title'>
            <h3 className='b-main-title m-3'>{title}</h3>
          </div>
          <div className='b-rent-item-main-content-description' dangerouslySetInnerHTML={{ __html }} />
          <div className='b-rent-item-main-content-btn'>
            <Link to={path} className='b-main-button m-ol'>Detailinfo</Link>
            <Link to='/kontakt' className='b-main-button'>Küsi pakkumist</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RentItem
